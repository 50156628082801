import React from "react";
import Homeimg from "../../assets/home.png";
import "./home.css";

const Home = () => {
  return (
    <div className="home">
      
      <div className="img"> 
        <img src={Homeimg} alt="Home" /> 
     
      </div>
      <div className="l">div
      </div>
    </div>
  );
};

export default Home;
