import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa6";
import { CiFacebook } from "react-icons/ci";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
const Footer = () => {
  return (
    <div className="padd">
    <div className="bottomp">
      <div className="footer">
        <div className="Socialnetwork">
          <Link to="/">Socialnetwork.in</Link>
        </div>
        {/*  links start here  */}
        <div className="links1">
          <h1 className="headd">Links</h1>
          <li>
            <Link>About Us</Link>
          </li>
          <li>
            <Link>Book Appoinment</Link>
          </li>
          <li>
            <Link>feedback</Link>
          </li>
        </div>
        {/*  links end here  */}
        {/* nav sections start */}
        <div className="navsec">
          <li>
            <Link>Home</Link>
          </li>
          <li>
        
            <Link>about</Link>
          </li>
          <li>
       
            <Link>Services</Link>
          </li>
        </div>
        {/* nav sections end */}

        {/* contac start  */}
        <div className="contact">
          <h1 className="headd">Contact</h1>
          <p>info@Socialnetworker.in</p>
          <li>t.+91 99888-77663</li>
          <p>
            e.
            <span className="gmail">
              <Link>Socialnetworker@gamil.com</Link>
            </span>
          </p>
        </div>

        {/* contac end  */}
        {/* subscribe start   */}
        <div className="subscribe">
          <h1 className="headd">subscribe</h1>
        <div className="em"> <h1> <input type="email" placeholder="email" />
          <button><FiArrowRight/></button></h1></div>
        </div>
        {/* subscribe end   */}

        {/* socialmedias start here  */}
        <div className="socialmed">
          <div>
            <Link>
              <FaInstagram />
            </Link>
          </div>
          <div>
            <Link>
              <CiFacebook />
            </Link>
          </div>
          <div>
            <Link>
              <FaLinkedin />
            </Link>
          </div>
          <div>
            <Link>
              <FaYoutube />
            </Link>
          </div>
        </div>
        {/* socialmedias start end  */}

        {/* copy right start  */}
        <div className="copyr">
          <li>&copy;2024 Official Site.All rights.</li>
          <div className="copye">
            <li>
              <Link>Socialnetwork.in</Link>
            </li>
            <li>
              <Link>Work with us</Link>
            </li>
            <li>
              <Link>Privacy Policy</Link>
            </li>
          </div>
        </div>
        {/* copy right end  */}
      </div>
    </div>
    </div>
  );
};

export default Footer;
